* {
  margin: 0;
  color: white;
  font-family: Lato, sans-serif;
}

.dashboardScreen__banner {
  position: relative;
  width: 100%;
  height: auto;
}

.dashboardScreen__content {
  align-items: left;
  text-align: left;
  padding: 40px 80px 80px 80px;
  width: 50%;
}

.dashboardScreen__content h1 {
  color: white;
  font-size: 2.4rem;
  padding: 20px 0;
  font-weight: 700;
}

.dashboardScreen__back {
  display: flex;
  padding-bottom: 20px;
  opacity: 0.7;
  align-items: top;
}

.dashboardScreen__back__img {
  filter: invert(1);
}

.dashboardScreen__back__msg {
  color: white;
  font-size: 1rem;
}

.dashboardScreen__banner__content {
  padding:40px 0;
}

.dashboardScreen__banner__content__logo img {
  max-width:250px;
}

.dashboardScreen__list {
  padding: 40px;
  display: flex;
  flex-direction: column;
}

.dashboardScreen__list h2 {
  font-size: 1.8rem;
  padding: 0 40px;
}

.dashboardScreen__list ul {
  padding: 40px;
  font-size: 1.2rem;
  /* justify-content: center;
  align-items: center;
  margin: 0 auto; */
}

.dashboardScreen__list ul li {
  list-style: none;
  padding: 20px 0;
}

.dashboardScreen__list ul li > p {
  padding-bottom: 10px;
  font-size: 1.2rem;
}

.dashboardScreen__list ul li p > span {
  font-size: 1.4rem;
}

.dashboardScreen__form__copyright {
  font-size: 1rem;
  padding: 20px;
  text-align: center;
}


/* Phones and Tablet*/
@media (max-width: 600px) {
}

/* Tablet and small laptop */
@media (max-width: 799px) {
  .dashboardScreen__content {
    align-items: left;
    text-align: left;
    padding: 20px;
    width: auto;
  }

  .dashboardScreen__content h1 {
    color: white;
    font-size: 1.8rem;
    line-height: 2.4rem;
    padding: 20px 0;
  }

  
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1200px) {
}
